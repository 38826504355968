
  import { createAsyncThunk } from "@reduxjs/toolkit";
import dealvpsService from "../../services/dealvpsService";

export const fetchData = createAsyncThunk(
  "inboundEmail/fetchData",
  async (_, { rejectWithValue }) => {
    try {
      const res = await dealvpsService.get(
        `/api/v1/general/get-all-table-data/Inbound Email`
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error?.resposnse?.data);
    }
  }
);

  