import { createSlice } from "@reduxjs/toolkit";
import { fetchTagApp } from "../../api/fetchAllData/fetchTagApp";



const initialState = {
  data: [],
  status: "idle", 
  error: null,
};


const TagAppdropdownSlice= createSlice({
    name: "TagAppName",
    initialState,
  
    extraReducers: (builder) => {
      builder
        .addCase(fetchTagApp.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(fetchTagApp.fulfilled, (state, action) => {
          console.log("fetchTagApp",action.payload);
          state.data = action.payload;
          state.status = "success";
          state.error = null;
        })
        .addCase(fetchTagApp.rejected, (state, action) => {
          state.status = "error";
          state.error = action.error.message;
        });
    },
  });

export default TagAppdropdownSlice.reducer;





