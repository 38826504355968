import { createSlice } from "@reduxjs/toolkit";
import { relatedTags } from "../../api/fetchAllData/relatedTag";


const initialState = {
  data: [],
  status: "idle", 
  error: null,
};


const relatedTagSlice= createSlice({
    name: "relatedTags",
    initialState,
    reducers: {
      clearRelatedTags(state) {
        state.data = []; // Reset data to an empty array
        state.status = "idle"; // Reset status to idle
        state.error = null; // Clear any errors
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(relatedTags.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(relatedTags.fulfilled, (state, action) => {
          console.log("relatedTags",action.payload);
          state.data = action.payload;
          state.status = "success";
          state.error = null;
        })
        .addCase(relatedTags.rejected, (state, action) => {
          state.status = "error";
          state.error = action.error.message;
        });
    },
  });
  export const { clearRelatedTags } = relatedTagSlice.actions;
export default relatedTagSlice.reducer;





