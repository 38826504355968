import { createAsyncThunk } from "@reduxjs/toolkit";
import dealvpsService from "../../services/dealvpsService";

export const emailTag =createAsyncThunk(
    "emailTag/fetchData",
    async (formData) => {
      try {
        const res = await dealvpsService.get(
          // `/api/v1/general/query/GET_ALL_INDIVIDUAL_NAMES_FROM_INDIVIDUAL_MANAGEMENT/${userName}`
           `api/v1/general/query/GET_ALL_EMAILS`
        );
        
        return res.data;
      } catch (error) {
        throw error;
      }
    }
  );