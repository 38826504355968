import { createAsyncThunk } from "@reduxjs/toolkit";
import dealvpsService from "../../services/dealvpsService";

export const relatedTags =createAsyncThunk(
    "relatedTags/fetchData",
    async (id) => {
      try {
        const res = await dealvpsService.get(
           `/api/v1/general/related/tickets/${id}?relatedFormName&start&size`
        );
        
        return res.data;
      } catch (error) {
        throw error;
      }
    }
  );

export const fetchrelatedData = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
  
      const res = await dealvpsService.get(
        `/api/v1/general/related/tickets/${id}?relatedFormName&start&size`,
        { headers: headers }
      );
  
      return res;
    } catch (error) {
      throw error;
    }
  };