import { createAsyncThunk } from "@reduxjs/toolkit";
import dealvpsService from "../../services/dealvpsService";

export const featchOpportunityData = createAsyncThunk(
  "Opportunity/fetchData",
  async (_, { rejectWithValue }) => {
    try {
      const userName = localStorage.getItem("userName");
      const res = await dealvpsService.get(
        `/api/v1/general/get-all-user-data/Opportunity/${userName}`
        //  `/api/v1/general/get-all-table-data/Opportunity`
        // `/api/v1/general/get-all-table-data/Opportunity/?filter=isDeleted=false`
      );
      
      return res.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
