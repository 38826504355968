import { createSlice } from "@reduxjs/toolkit";
import { featchCurrency } from "../../api/TransactionService/featchCurrency";
import { featchDealType } from "../../api/DealService/featchDealType";
import { featchfundingneed } from "../../api/ProjectService/featchfundingneed";


const initialState = {
  data: [],
  status: "idle", 
  error: null,
};

const fundingNeedSlice= createSlice({
    name: "FundingNeedDetail",
    initialState,
  
    extraReducers: (builder) => {
      builder
        .addCase(featchfundingneed.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(featchfundingneed.fulfilled, (state, action) => {
          console.log("featchCurrency",action.payload);
          state.data = action.payload;
          state.status = "success";
          state.error = null;
        })
        .addCase(featchfundingneed.rejected, (state, action) => {
          state.status = "error";
          state.error = action.error.message;
        });
    },
  });

export default fundingNeedSlice.reducer;





