import { createSlice } from "@reduxjs/toolkit";
import { fetchData } from "../../api/outBoundEmail/fetchOutboundEmail";
const initialState = {
  data: [],
  status: "idle", 
  error: null,
};

const OutboundSlice = createSlice({
  name: "inboundDetail",
  initialState,
  reducers: {
    clearOutbound: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { clearInbound } = OutboundSlice.actions;
export default OutboundSlice.reducer;