import { createSlice } from '@reduxjs/toolkit';

const selectedTagsSlice = createSlice({
  name: 'selectedTags',
  initialState: {
    selectedRows: {}, // Stores selected rows for each tab
  },
  reducers: {
    setSelectedRows: (state, action) => {
      const { tab, selectedRows } = action.payload;
      state.selectedRows[tab] = selectedRows; // Update state for the active tab
    },
    clearSelectedRows: (state) => {
      state.selectedRows = {}; // Clear all selections
    },
  },
});

export const { setSelectedRows, clearSelectedRows } = selectedTagsSlice.actions;
export default selectedTagsSlice.reducer;
